<template>
    <div>
        <b-container>
            <section class="reports">
                <div class="sector pb-0">
                    <h2 class="main-title">Reports</h2>
                    <p class="main-subtitle">Keep up with the latest reports</p>
                    <b-row>
                        <b-col cols="12" md="6" xl="4">
                            <div class="reports__element">
                                <div class="reports__content">
                                    <b-row>
                                        <b-col cols="12" sm="3">
                                            <img class="img-fluid reports__icon" src="../assets/images/reports/pdf.svg" alt="pdf" />
                                        </b-col>
                                        <b-col cols="12" sm="9">
                                            <p class="reports__date"><span class="agencies__star-tooltip" v-b-tooltip title="Report date"><simple-line-icons icon="calendar" size="small" color="#504e70" /></span>12.05.2019</p>
                                            <h3 v-if="desktopMode" class="reports__title" v-line-clamp:22="2">Global Annual Agency Report</h3>
                                            <h3 v-else class="reports__title" v-line-clamp:22="0">Global Annual Agency Report</h3>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div class="reports__details">
                                    <b-row class="mt-3">
                                        <b-col cols="12" sm="6">
                                            <a class="details-button details-button--full details-button details-button--full--full mb-2 mb-sm-0" href="#"><simple-line-icons icon="doc" size="small" color="#504e70" />Open</a>
                                        </b-col>
                                        <b-col cols="12" sm="6">
                                            <a class="details-button details-button--full details-button details-button--full--full" href="#"><simple-line-icons icon="paperClip" size="small" color="#504e70" />Download</a>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="12" md="6" xl="4">
                            <div class="reports__element">
                                <div class="reports__content">
                                    <b-row>
                                        <b-col cols="12" sm="3">
                                            <img class="img-fluid reports__icon" src="../assets/images/reports/pdf.svg" alt="pdf" />
                                        </b-col>
                                        <b-col cols="12" sm="9">
                                            <p class="reports__date"><span class="agencies__star-tooltip" v-b-tooltip title="Report date"><simple-line-icons icon="calendar" size="small" color="#504e70" /></span>12.05.2019</p>
                                            <h3 v-if="desktopMode" class="reports__title" v-line-clamp:22="2">Global Annual Agency Report</h3>
                                            <h3 v-else class="reports__title" v-line-clamp:22="0">Global Annual Agency Report</h3>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div class="reports__details">
                                    <b-row class="mt-3">
                                        <b-col cols="12" sm="6">
                                            <a class="details-button details-button--full mb-2 mb-sm-0" href="#"><simple-line-icons icon="doc" size="small" color="#504e70" />Open</a>
                                        </b-col>
                                        <b-col cols="12" sm="6">
                                            <a class="details-button details-button--full" href="#"><simple-line-icons icon="paperClip" size="small" color="#504e70" />Download</a>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="12" md="6" xl="4">
                            <div class="reports__element">
                                <div class="reports__content">
                                    <b-row>
                                        <b-col cols="12" sm="3">
                                            <img class="img-fluid reports__icon" src="../assets/images/reports/pdf.svg" alt="pdf" />
                                        </b-col>
                                        <b-col cols="12" sm="9">
                                            <p class="reports__date"><span class="agencies__star-tooltip" v-b-tooltip title="Report date"><simple-line-icons icon="calendar" size="small" color="#504e70" /></span>12.05.2019</p>
                                            <h3 v-if="desktopMode" class="reports__title" v-line-clamp:22="2">Global Annual Agency Report</h3>
                                            <h3 v-else class="reports__title" v-line-clamp:22="0">Global Annual Agency Report</h3>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div class="reports__details">
                                    <b-row class="mt-3">
                                        <b-col cols="12" sm="6">
                                            <a class="details-button details-button--full mb-2 mb-sm-0" href="#"><simple-line-icons icon="doc" size="small" color="#504e70" />Open</a>
                                        </b-col>
                                        <b-col cols="12" sm="6">
                                            <a class="details-button details-button--full" href="#"><simple-line-icons icon="paperClip" size="small" color="#504e70" />Download</a>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="12" md="6" xl="4">
                            <div class="reports__element">
                                <div class="reports__content">
                                    <b-row>
                                        <b-col cols="12" sm="3">
                                            <img class="img-fluid reports__icon" src="../assets/images/reports/pdf.svg" alt="pdf" />
                                        </b-col>
                                        <b-col cols="12" sm="9">
                                            <p class="reports__date"><span class="agencies__star-tooltip" v-b-tooltip title="Report date"><simple-line-icons icon="calendar" size="small" color="#504e70" /></span>12.05.2019</p>
                                            <h3 v-if="desktopMode" class="reports__title" v-line-clamp:22="2">Global Annual Agency Report</h3>
                                            <h3 v-else class="reports__title" v-line-clamp:22="0">Global Annual Agency Report</h3>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div class="reports__details">
                                    <b-row class="mt-3">
                                        <b-col cols="12" sm="6">
                                            <a class="details-button details-button--full mb-2 mb-sm-0" href="#"><simple-line-icons icon="doc" size="small" color="#504e70" />Open</a>
                                        </b-col>
                                        <b-col cols="12" sm="6">
                                            <a class="details-button details-button--full" href="#"><simple-line-icons icon="paperClip" size="small" color="#504e70" />Download</a>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="12" md="6" xl="4">
                            <div class="reports__element">
                                <div class="reports__content">
                                    <b-row>
                                        <b-col cols="12" sm="3">
                                            <img class="img-fluid reports__icon" src="../assets/images/reports/pdf.svg" alt="pdf" />
                                        </b-col>
                                        <b-col cols="12" sm="9">
                                            <p class="reports__date"><span class="agencies__star-tooltip" v-b-tooltip title="Report date"><simple-line-icons icon="calendar" size="small" color="#504e70" /></span>12.05.2019</p>
                                            <h3 v-if="desktopMode" class="reports__title" v-line-clamp:22="2">Global Annual Agency Report</h3>
                                            <h3 v-else class="reports__title" v-line-clamp:22="0">Global Annual Agency Report Expo</h3>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div class="reports__details">
                                    <b-row class="mt-3">
                                        <b-col cols="12" sm="6">
                                            <a class="details-button details-button--full mb-2 mb-sm-0" href="#"><simple-line-icons icon="doc" size="small" color="#504e70" />Open</a>
                                        </b-col>
                                        <b-col cols="12" sm="6">
                                            <a class="details-button details-button--full" href="#"><simple-line-icons icon="paperClip" size="small" color="#504e70" />Download</a>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </section>
        </b-container>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
export default {
  components: {
    SimpleLineIcons
  },
  data: function () {
    return {
      screenWidth: 0,
      desktopMode: false
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.screenWidth = window.innerWidth
      if (this.screenWidth > 768) {
        this.desktopMode = true
      } else {
        this.desktopMode = false
      }
    }
  }
}
</script>
