<template>
    <div>
        <ReportsList />
    </div>
</template>

<script>
import ReportsList from '@/components/ReportsList.vue'
export default {
  components: {
    ReportsList
  }
}
</script>
